// default brand name
export const DEFAULT_BRAND_NAME = "wrisk";
export const USER_DATA = "USER_DATA";
// Name of the chat bot
export const BOT_NAME = "Bot";
// Number of messages fetched at a time from Conversations SDK
export const MESSAGES_LOAD_COUNT = 20;

// The maximum number of characters allowed in message input
export const CHAR_LIMIT = 32 * 1024; // 32kB

// The height of the loading spinner box shown in the message list when fetching new messages
export const MESSAGES_SPINNER_BOX_HEIGHT = 50;

export const LOCALSTORAGE_SESSION_ITEM_ID = "TWILIO_WEBCHAT_WIDGET";
