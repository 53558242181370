import { IconWrapper, IconWrapperProps } from "@twilio-paste/icons/cjs/helpers/IconWrapper";
import { forwardRef } from "react";
import { useSelector } from "react-redux";

import { IconNames } from "../branding";
import { getIcons } from "../selectors";

interface IconProps extends IconWrapperProps {
    icon: IconNames;
}

export const Icon = forwardRef<HTMLElement, IconProps>(({ icon, ...props }, ref) => {
    const icons = useSelector(getIcons);
    const iconComponent = icons?.[icon];

    return iconComponent ? (
        <IconWrapper {...props} ref={ref}>
            {iconComponent}
        </IconWrapper>
    ) : null;
});
