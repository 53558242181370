import { AnyAction, Reducer } from "redux";

import { UserState } from "./definitions";
import { ACTION_LOAD_USER_CONFIG } from "./actions/actionTypes";

const initialState: UserState = {};

export const UserReducer: Reducer = (state: UserState = initialState, action: AnyAction): UserState => {
    switch (action.type) {
        case ACTION_LOAD_USER_CONFIG: {
            return {
                ...state,
                ...action.payload
            };
        }

        default:
            return state;
    }
};
