import { Flex } from "@twilio-paste/core/flex";
import { Text } from "@twilio-paste/core/text";
import { Button } from "@twilio-paste/core/button";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import {
    changeEngagementPhase,
    updateConversationRated,
    updatePreEngagementData,
    updateUnauthorizedFormVisibility
} from "../store/actions/genericActions";
import { EngagementPhase } from "../store/definitions";
import { Header } from "./Header";
import { NotificationBar } from "./NotificationBar";
import { mainCCClosedText } from "./styles/ConversationEnded.styles";
import { sessionDataHandler } from "../sessionDataHandler";

export const OutsideWorkingHoursPage = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation("translation", { keyPrefix: "OutsideWorkingHoursPage" });

    const handleClick = async () => {
        sessionDataHandler.clear();
        dispatch(updatePreEngagementData({ email: "", name: "", query: "" }));
        dispatch(updateUnauthorizedFormVisibility({ unauthorizedFormSubmitted: false }));
        dispatch(updateConversationRated({ conversationRated: false }));
        dispatch(changeEngagementPhase({ phase: EngagementPhase.InitialPhase }));
    };

    return (
        <>
            <Header />
            <NotificationBar />
            <Flex hAlignContent="center" height="60%" vertical vAlignContent="center">
                <Flex width="90%">
                    <Text element="TITLE_TEXT" as="p" {...mainCCClosedText}>
                        {t("Title")}
                    </Text>
                </Flex>
                <Flex width="90%">
                    <Text as="br" />
                </Flex>
                <Flex width="90%">
                    <Text as="p" style={{ fontWeight: "bold" }}>
                        {t("Office hours")}
                    </Text>
                </Flex>
                <Flex width="90%">
                    <Text as="p"> {t("Monday to Friday hours")}</Text>
                </Flex>
                <Flex width="90%">
                    <Text as="p"> {t("Bank holidays hours")}</Text>
                </Flex>
                <Flex width="90%">
                    <Text as="br" />
                </Flex>
                <Flex width="90%">
                    <Text as="p"> {t("For immediate assistance, try our chatbot, available 24/7")}</Text>
                </Flex>
                <Flex width="90%">
                    <Text as="br" />
                </Flex>
                <Flex width="90%">
                    <Button
                        variant="secondary"
                        fullWidth
                        onClick={(event) => {
                            event.preventDefault();
                            handleClick();
                        }}
                    >
                        {t("Try chatbot-button")}
                    </Button>
                </Flex>
            </Flex>
        </>
    );
};
