import { ReactElement } from "react";
import merge from "lodash.merge";

import { ReactComponent as WriskLogo } from "./icons/wrisk/logo.svg";
import { ReactComponent as WriskChevron } from "./icons/wrisk/chevron.svg";
import { ReactComponent as WriskChat } from "./icons/wrisk/chat.svg";
import { ReactComponent as AxaLogo } from "./icons/axavwfs/logo.svg";
import { ReactComponent as AxaChevron } from "./icons/axavwfs/chevron.svg";
import { ReactComponent as AxaChat } from "./icons/axavwfs/chat.svg";
import { ReactComponent as BmwLogo } from "./icons/bmw/logo.svg";
import { ReactComponent as BmwChevron } from "./icons/bmw/chevron.svg";
import { ReactComponent as BmwChat } from "./icons/bmw/chat.svg";
import { ReactComponent as HeycarLogo } from "./icons/heycar/logo.svg";
import { ReactComponent as HeycarChevron } from "./icons/heycar/chevron.svg";
import { ReactComponent as HeycarChat } from "./icons/heycar/chat.svg";
import { ReactComponent as JaguarLogo } from "./icons/jaguar/logo.svg";
import { ReactComponent as JaguarChevron } from "./icons/jaguar/chevron.svg";
import { ReactComponent as JaguarChat } from "./icons/jaguar/chat.svg";
import { ReactComponent as LandRoverLogo } from "./icons/landrover/logo.svg";
import { ReactComponent as LandRoverChevron } from "./icons/landrover/chevron.svg";
import { ReactComponent as LandRoverChat } from "./icons/landrover/chat.svg";
import { ReactComponent as MercedesLogo } from "./icons/mercedes/logo.svg";
import { ReactComponent as MercedesChevron } from "./icons/mercedes/chevron.svg";
import { ReactComponent as MercedesChat } from "./icons/mercedes/chat.svg";
import { ReactComponent as MiniLogo } from "./icons/mini/logo.svg";
import { ReactComponent as MiniChevron } from "./icons/mini/chevron.svg";
import { ReactComponent as MiniChat } from "./icons/mini/chat.svg";
import { ReactComponent as VolvoLogo } from "./icons/volvo/logo.svg";
import { ReactComponent as VolvoChevron } from "./icons/volvo/chevron.svg";
import { ReactComponent as VolvoChat } from "./icons/volvo/chat.svg";
import { ReactComponent as BotIcon } from "./icons/default/bot.svg";
import { ReactComponent as ChatButtonIcon } from "./icons/default/chat.svg";
import { ReactComponent as SendIcon } from "./icons/default/send.svg";
import { ReactComponent as ChevronIcon } from "./icons/default/chevron.svg";

export type IconNames = "logo" | "send" | "bot" | "chevron" | "chat";

type Icons = Partial<Record<IconNames, ReactElement>>;

const defaultIcons: Icons = {
    bot: <BotIcon />,
    chat: <ChatButtonIcon />,
    send: <SendIcon />,
    chevron: <ChevronIcon />
};

const brandIcons: Record<string, Icons> = {
    wrisk: {
        logo: <WriskLogo />,
        chevron: <WriskChevron />,
        chat: <WriskChat />
    },
    axavwfs: {
        logo: <AxaLogo />,
        chevron: <AxaChevron />,
        chat: <AxaChat />
    },
    bmw: {
        logo: <BmwLogo />,
        chevron: <BmwChevron />,
        chat: <BmwChat />
    },
    heycar: {
        logo: <HeycarLogo />,
        chevron: <HeycarChevron />,
        chat: <HeycarChat />
    },
    jaguar: {
        logo: <JaguarLogo />,
        chevron: <JaguarChevron />,
        chat: <JaguarChat />
    },
    landrover: {
        logo: <LandRoverLogo />,
        chevron: <LandRoverChevron />,
        chat: <LandRoverChat />
    },
    mercedes: {
        logo: <MercedesLogo />,
        chevron: <MercedesChevron />,
        chat: <MercedesChat />
    },
    mini: {
        logo: <MiniLogo />,
        chevron: <MiniChevron />,
        chat: <MiniChat />
    },
    volvo: {
        logo: <VolvoLogo />,
        chevron: <VolvoChevron />,
        chat: <VolvoChat />
    }
};

export const createIcons = (brandName: string): Icons => merge({}, defaultIcons, brandIcons[brandName] ?? {});
