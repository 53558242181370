import { AnyAction, Reducer } from "redux";

import { EngagementPhase, SessionState } from "./definitions";
import {
    ACTION_CHANGE_BOT_TYPING_STATUS,
    ACTION_CHANGE_ENGAGEMENT_PHASE,
    ACTION_CHANGE_EXPANDED_STATUS,
    ACTION_CHANGE_START_CHAT_CLICKED,
    ACTION_SHOW_MESSAGE_INPUT,
    ACTION_SHOW_UNAUTHORIZED_FORM,
    ACTION_START_SESSION,
    ACTION_UPDATE_PRE_ENGAGEMENT_DATA,
    ACTION_UPDATE_SESSION_DATA,
    ACTION_CHANGE_CONVERSATION_RATED,
    ACTION_TEMPORARILY_HIDE_ENTRYPOINT
} from "./actions/actionTypes";

const initialState: SessionState = {
    currentPhase: EngagementPhase.MessagingCanvas,
    expanded: false,
    preEngagementData: { name: "", query: "", email: "" },
    showMessageInput: false,
    isBotTyping: false,
    startChatClicked: false,
    unauthorizedFormSubmitted: false,
    conversationRated: false,
    temporarilyHideEntryPoint: false
};

export const SessionReducer: Reducer<SessionState> = (
    state: SessionState = initialState,
    action: AnyAction
): SessionState => {
    switch (action.type) {
        case ACTION_START_SESSION: {
            return {
                ...state,
                token: action.payload.token,
                conversationSid: action.payload.conversationSid,
                currentPhase: action.payload.currentPhase
            };
        }

        case ACTION_UPDATE_SESSION_DATA: {
            return {
                ...state,
                token: action.payload.token,
                conversationSid: action.payload.conversationSid
            };
        }

        case ACTION_CHANGE_EXPANDED_STATUS: {
            return {
                ...state,
                expanded: action.payload.expanded
            };
        }

        case ACTION_TEMPORARILY_HIDE_ENTRYPOINT: {
            return {
                ...state,
                temporarilyHideEntryPoint: action.payload
            };
        }

        case ACTION_CHANGE_ENGAGEMENT_PHASE: {
            return {
                ...state,
                currentPhase: action.payload.currentPhase
            };
        }

        case ACTION_UPDATE_PRE_ENGAGEMENT_DATA: {
            return {
                ...state,
                preEngagementData: {
                    ...state.preEngagementData,
                    ...action.payload.preEngagementData
                }
            };
        }

        case ACTION_SHOW_MESSAGE_INPUT: {
            return {
                ...state,
                showMessageInput: action.payload.showMessageInput
            };
        }

        case ACTION_SHOW_UNAUTHORIZED_FORM: {
            return {
                ...state,
                unauthorizedFormSubmitted: action.payload.unauthorizedFormSubmitted
            };
        }

        case ACTION_CHANGE_BOT_TYPING_STATUS: {
            return {
                ...state,
                isBotTyping: action.payload.isBotTyping
            };
        }

        case ACTION_CHANGE_START_CHAT_CLICKED: {
            return {
                ...state,
                startChatClicked: action.payload.startChatClicked
            };
        }

        case ACTION_CHANGE_CONVERSATION_RATED: {
            return {
                ...state,
                conversationRated: action.payload.conversationRated
            };
        }

        default:
            return state;
    }
};
