/* eslint-disable no-nested-ternary */

import { useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Header } from "./Header";
import { MessageList } from "./MessageList";
import { MessageInput } from "./MessageInput";
import { RatingPage } from "./RatingPage";
import { ConversationEnded } from "./ConversationEnded";
import { NotificationBar } from "./NotificationBar";
import { removeNotification } from "../store/actions/genericActions";
import { notifications } from "../notifications";
import { AttachFileDropArea } from "./AttachFileDropArea";
import { getConversationState } from "../selectors";

export const MessagingCanvasPhase = () => {
    const dispatch = useDispatch();
    const { conversationState, conversationRated } = useSelector(getConversationState);

    useEffect(() => {
        dispatch(removeNotification(notifications.failedToInitSessionNotification("ds").id));
    }, [dispatch]);

    const Wrapper = conversationState === "active" ? AttachFileDropArea : Fragment;
    return (
        <Wrapper>
            <Header />
            <NotificationBar />
            <MessageList />
            {conversationState === "active" || conversationRated || localStorage.getItem("conversationRated") ? (
                conversationState === "active" ? (
                    <MessageInput />
                ) : (
                    <ConversationEnded />
                )
            ) : (
                <RatingPage />
            )}
        </Wrapper>
    );
};
