import { FunctionComponent, PropsWithChildren, useMemo } from "react";
import { Globals as AnimatedGlobals, useReducedMotion } from "@twilio-paste/core/animation-library";
import { DefaultTheme, StyledBase } from "@twilio-paste/core/theme";
import { CreateCustomTheme, CustomizationProviderProps, CustomTheme } from "@twilio-paste/core/customization";
import { StylingGlobals, ThemeProvider, css } from "@twilio-paste/core/styling-library";
import merge from "lodash.merge";

const createCustomTheme = ({ baseTheme, overrides, customBreakpoints, elements }: CreateCustomTheme): CustomTheme => ({
    ...merge(baseTheme, overrides),
    breakpoints: customBreakpoints ?? baseTheme.breakpoints,
    elements
});

const pasteGlobalStyles = css({
    "*, *::after, *::before": {
        boxSizing: "border-box"
    },
    "@media (prefers-reduced-motion: reduce)": {
        "*": {
            animationDuration: "0 !important",
            animationIterationCount: "1 !important",
            transitionDuration: "0 !important",
            scrollBehavior: "auto !important"
        }
    }
});

export const WriskCustomisationProvider: FunctionComponent<PropsWithChildren<CustomizationProviderProps>> = ({
    baseTheme = "default",
    customBreakpoints,
    elements,
    theme,
    disableAnimations = false,
    ...props
}) => {
    const prefersReducedMotion = useReducedMotion();

    useMemo(
        () =>
            AnimatedGlobals.assign({
                skipAnimation: disableAnimations || prefersReducedMotion
            }),
        [disableAnimations, prefersReducedMotion]
    );

    const customTheme = useMemo(
        () =>
            createCustomTheme({
                baseTheme: DefaultTheme,
                overrides: theme ?? {},
                elements: elements ?? {},
                customBreakpoints
            }),
        [customBreakpoints, elements, theme]
    );

    return (
        <ThemeProvider theme={customTheme}>
            <StylingGlobals styles={pasteGlobalStyles({ theme: customTheme })} />
            <StyledBase {...props} />
        </ThemeProvider>
    );
};
