import { Client, Conversation, Participant, Message, User } from "@twilio/conversations";
import { GenericThemeShape } from "@twilio-paste/core/theme";
import { PasteCustomCSS } from "@twilio-paste/core/customization";
import { ReactNode } from "react";

import { AlertVariants } from "../notifications";
import { FileAttachmentConfig, TranscriptConfig } from "../definitions";
import { IconNames } from "../branding";

export enum EngagementPhase {
    PreEngagementForm = "PreEngagementForm",
    MessagingCanvas = "MessagingCanvas",
    Loading = "Loading",
    InitialPhase = "InitialPhase",
    InitialStaticPage = "InitialStaticPage",
    UnauthorizedFormPhase = "UnauthorizedFormPhase",
    CCClosedPage = "CCClosedPage",
    RatingPhase = "RatingPhase"
}

export type ChatState = {
    conversationsClient?: Client;
    conversation?: Conversation;
    participants?: Participant[];
    users?: User[];
    messages?: Message[];
    attachedFiles?: File[];
    conversationState?: string;
};

export type PreEngagementData = { name: string; email: string; query: string };

export type SessionState = {
    currentPhase: EngagementPhase;
    expanded: boolean;
    token?: string;
    conversationSid?: string;
    conversationsClient?: Client;
    conversation?: Conversation;
    users?: User[];
    participants?: Participant[];
    messages?: Message[];
    conversationState?: "active" | "inactive" | "closed";
    preEngagementData?: PreEngagementData;
    showMessageInput: boolean;
    isBotTyping: boolean;
    startChatClicked: boolean;
    unauthorizedFormSubmitted: boolean;
    conversationRated: boolean;
    temporarilyHideEntryPoint: boolean;
};

export type ConfigState = {
    serverUrl?: string;
    theme?: {
        overrides?: Partial<GenericThemeShape>;
        elements?: Record<string, PasteCustomCSS>;
        icons?: Partial<Record<IconNames, ReactNode>>;
    };
    fileAttachment?: FileAttachmentConfig;
    transcript?: TranscriptConfig;
    brandName?: string;
    lng?: string;
    fallbackLng?: string;
};

export type UserState = {
    userId?: string;
    firstName?: string;
    lastName?: string;
    emailAddress?: string;
    language?: string;
};

export type Notification = {
    dismissible: boolean;
    id: string;
    onDismiss?: () => void;
    messageKey: string;
    messageOptions?: { [key: string]: any };
    timeout?: number;
    type: AlertVariants;
};

export type NotificationState = Notification[];

export type AppState = {
    chat: ChatState;
    config: ConfigState;
    session: SessionState;
    notifications: NotificationState;
    user: UserState;
    temporarilyHideEntryPoint: boolean;
};

type PreEngagementDataAttributes = {
    brandName: string;
    query?: string;
    friendlyName?: string;
    email?: string;
};

export type ConversationAttributes = {
    conversationSid: string;
    userAuthorized?: boolean;
    // eslint-disable-next-line camelcase
    pre_engagement_data?: PreEngagementDataAttributes;
    clientFirstName?: string;
    clientEmail?: string;
    clientQuery?: string;
};

export type MessageAttributes = {
    options: { [name: string]: string } | string[];
    selectableClientResponse?: boolean;
    displayInput?: boolean;
    displayUnauthorizedForm?: boolean;
};
