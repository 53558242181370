import { Conversation, Message } from "@twilio/conversations";
import { Dispatch } from "redux";

import {
    ACTION_ADD_MESSAGE,
    ACTION_CHANGE_BOT_TYPING_STATUS,
    ACTION_REMOVE_MESSAGE,
    ACTION_UPDATE_MESSAGE
} from "../actionTypes";
import { BOT_NAME } from "../../../constants";

export const initMessagesListener = (conversation: Conversation, dispatch: Dispatch) => {
    conversation.addListener("messageAdded", (message: Message) => {
        dispatch({
            type: ACTION_ADD_MESSAGE,
            payload: { message }
        });
        if (message.author === BOT_NAME) {
            dispatch({
                type: ACTION_CHANGE_BOT_TYPING_STATUS,
                payload: { isBotTyping: false }
            });
        }
    });
    conversation.addListener("messageRemoved", (message: Message) => {
        dispatch({
            type: ACTION_REMOVE_MESSAGE,
            payload: { message }
        });
    });
    conversation.addListener("messageUpdated", ({ message }) => {
        dispatch({
            type: ACTION_UPDATE_MESSAGE,
            payload: { message }
        });
    });
    conversation.addListener("removed", () =>
        dispatch({
            // Conversation ended, make sure bot isn't still "typing"
            type: ACTION_CHANGE_BOT_TYPING_STATUS,
            payload: { isBotTyping: false }
        })
    );
};
