/* eslint-disable camelcase */

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@twilio-paste/core/box";
import { Text } from "@twilio-paste/core/text";
import Rating from "@mui/material/Rating";
import { Input } from "@twilio-paste/core/input";
import { Button } from "@twilio-paste/core/button";
import { useTranslation } from "react-i18next";

import { updateConversationRated } from "../store/actions/genericActions";
import { containerStyles, textStyles, titleStyles } from "./styles/ConversationEnded.styles";
import { sessionDataHandler } from "../sessionDataHandler";
import { fieldStyles, formStyles } from "./styles/PreEngagementFormPhase.styles";
import { getConversation } from "../selectors";

const emailRegEx = /\S+@[a-z\d]+\S+\.[a-z\d]+$/gim;

export const RatingPage = () => {
    const logger = window.Twilio.getLogger("RatingPage");
    const { conversation } = useSelector(getConversation);
    const [ratingScore, setRatingScore] = useState(0);
    const [ratingContext, setRatingContext] = useState("");
    const [isDisabled, setIsDisabled] = useState(true);
    const dispatch = useDispatch();
    const { t } = useTranslation("translation", { keyPrefix: "RatingPage" });

    const getAgents = async () => {
        const allAgents = (await conversation?.getParticipants())?.filter(
            ({ identity }) => identity?.includes("_2E") ?? emailRegEx?.test(identity ?? "")
        );
        allAgents?.sort((a, b) => (a?.dateCreated?.getTime() ?? 0) - (b?.dateCreated?.getTime() ?? 0));
        const lastAgent = allAgents?.at(-1); // As we only do cold transfers, will only ever be one agent
        const allOtherAgents = allAgents?.filter((x) => x !== lastAgent);

        return {
            lastAgent,
            allAgents,
            allOtherAgents
        };
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        dispatch(updateConversationRated({ conversationRated: true }));
        localStorage.setItem("conversationRated", "true");

        if (!conversation) {
            logger.error("Failed save rating: no conversation found");
            return;
        }

        const { lastAgent } = await getAgents();
        await sessionDataHandler.updateTaskConversationAttributes(conversation.sid, {
            conversation_measure_1: ratingScore,
            conversation_attribute_4: lastAgent?.identity ?? "no human agents found",
            conversation_attribute_5: ratingContext
        });
        await sessionDataHandler.stopTrackingTask(conversation.sid);
    };

    const handleStarChange = (value: number) => {
        setIsDisabled(false);
        setRatingScore(value);
    };

    return (
        <Box {...containerStyles}>
            <Box as="form" data-test="rating-form" onSubmit={handleSubmit} {...formStyles}>
                <Text element="TITLE_TEXT" as="h3" {...titleStyles}>
                    {t("Please rate your support experience.")}
                </Text>
                <Text as="p" {...textStyles}>
                    <Rating name="size-large" size="large" onChange={(e, v) => handleStarChange(v ?? 0)} />
                </Text>
                <Box {...fieldStyles}>
                    <Input
                        type="text"
                        placeholder={t("Leave a comment-placeholder")}
                        element="MESSAGE_INPUT_FORM"
                        name="rating-context"
                        data-test="rating-form-input"
                        onChange={(e) => setRatingContext(e.target.value)}
                    />
                </Box>
                <Box>
                    <Button
                        type="submit"
                        element="MANAGE_BUTTONS_PRIMARY"
                        variant="primary"
                        fullWidth
                        disabled={isDisabled}
                    >
                        {t("Submit feedback-button")}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};
