import { Flex } from "@twilio-paste/core/flex";
import { Text } from "@twilio-paste/core/text";
import { Button } from "@twilio-paste/core/button";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { changeEngagementPhase } from "../store/actions/genericActions";
import { EngagementPhase } from "../store/definitions";
import { Header } from "./Header";
import { NotificationBar } from "./NotificationBar";
import { loadingTextStyles } from "./styles/ConversationEnded.styles";
import { getFirstName } from "../selectors";

export const InitialStaticPage = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation("translation", { keyPrefix: "InitialStaticPage" });
    const firstName = useSelector(getFirstName);

    const handleClick = async () => {
        dispatch(changeEngagementPhase({ phase: EngagementPhase.InitialPhase }));
    };

    return (
        <>
            <Header />
            <NotificationBar />
            <Flex hAlignContent="center" height="75%" vertical vAlignContent="center">
                <Flex width="90%">
                    <Text as="p" {...loadingTextStyles}>
                        {t("Hi there, welcome to our live chat", {
                            context: firstName?.length > 1 ? "known_name" : "unknown_name",
                            firstName
                        })}
                    </Text>
                </Flex>
                <Flex width="90%">
                    <Button
                        variant="primary"
                        fullWidth
                        onClick={(event) => {
                            event.preventDefault();
                            handleClick();
                        }}
                    >
                        {t("Chat to us-button")}
                    </Button>
                </Flex>
            </Flex>
        </>
    );
};
