import { Conversation } from "@twilio/conversations";
import { Dispatch } from "redux";

import { changeEngagementPhase } from "../genericActions";
import { EngagementPhase } from "../../definitions";
import { ACTION_UPDATE_CONVERSATION_STATE } from "../actionTypes";

const ccAvailable = (conversation: any): boolean => {
    const attributes = conversation?.attributes;

    return JSON.parse(attributes?.hasOwnProperty("ccClosed"));
};

export const initConversationListener = (conversation: Conversation, dispatch: Dispatch) => {
    conversation.addListener("updated", ({ conversation: updatedConversation, updateReasons }) => {
        // we are listening only to a subset of events.
        if (updateReasons?.includes("state")) {
            if (ccAvailable(conversation)) {
                dispatch(changeEngagementPhase({ phase: EngagementPhase.CCClosedPage }));
            } else {
                dispatch({
                    type: ACTION_UPDATE_CONVERSATION_STATE,
                    payload: { conversationState: updatedConversation?.state?.current }
                });
            }
        }
    });
};
