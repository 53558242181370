/* eslint-disable react-hooks/exhaustive-deps */
import { Media, Message } from "@twilio/conversations";
import { Box } from "@twilio-paste/core/box";
import { ScreenReaderOnly } from "@twilio-paste/core/screen-reader-only";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Text } from "@twilio-paste/core/text";
import { Flex } from "@twilio-paste/core/flex";
import { KeyboardEvent, useEffect, useRef, useState } from "react";
import { SuccessIcon } from "@twilio-paste/icons/esm/SuccessIcon";

import { MessageBubbleSelectable } from "./MessageBubbleSelectable";
import { EngagementPhase, MessageAttributes } from "../store/definitions";
import { changeEngagementPhase, updateMessageInputVisibility } from "../store/actions/genericActions";
import { FilePreview } from "./FilePreview";
import { parseMessageBody } from "../utils/parseMessageBody";
import {
    getInnerContainerStyles,
    authorStyles,
    timeStampStyles,
    bodyStyles,
    outerContainerStyles,
    readStatusStyles,
    bubbleAndAvatarContainerStyles
} from "./styles/MessageBubble.styles";
import { Icon } from "./Icon";
import { getMessageBubbleData } from "../selectors";
import { BOT_NAME } from "../constants";

export const MessageBubble = ({
    message,
    isLast,
    focusable,
    updateFocus
}: {
    message: Message;
    isLast: boolean;
    isLastOfUserGroup: boolean;
    focusable: boolean;
    updateFocus: (newFocus: number) => void;
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation("translation", { keyPrefix: "MessageBubble" });
    const [read, setRead] = useState(false);
    const [isMouseDown, setIsMouseDown] = useState(false);
    const { conversationsClient, participants, users, fileAttachmentConfig, unauthorizedFormSubmitted } =
        useSelector(getMessageBubbleData);

    const messageRef = useRef<HTMLDivElement>(null);

    const belongsToCurrentUser = message.author === conversationsClient?.user.identity;
    const attributes = message.attributes as MessageAttributes;

    useEffect(() => {
        if (isLast && participants && belongsToCurrentUser) {
            const getOtherParticipants = participants.filter((p) => p.identity !== conversationsClient?.user.identity);
            setRead(
                Boolean(getOtherParticipants.length) &&
                    getOtherParticipants.every((p) => p.lastReadMessageIndex === message.index)
            );
        } else {
            setRead(false);
        }
    }, [participants, isLast, belongsToCurrentUser, conversationsClient, message]);

    useEffect(() => {
        if (focusable) {
            messageRef.current?.focus();
        }
    }, [focusable]);

    useEffect(() => {
        const { displayInput, displayUnauthorizedForm } = attributes;
        if (displayInput) {
            dispatch(updateMessageInputVisibility({ showMessageInput: displayInput }));
        }
        if (
            displayUnauthorizedForm &&
            !unauthorizedFormSubmitted &&
            !localStorage.getItem("unauthorizedFormSubmitted")
        ) {
            dispatch(changeEngagementPhase({ phase: EngagementPhase.UnauthorizedFormPhase }));
        }
    }, []);

    const renderMedia = () => {
        if (fileAttachmentConfig?.enabled) {
            if (!message.attachedMedia) return null;

            return message.attachedMedia.map((media: Media) => {
                const file = {
                    name: media.filename,
                    type: media.contentType,
                    size: media.size
                } as File;
                return <FilePreview key={media.sid} file={file} isBubble={true} media={media} focusable={focusable} />;
            });
        }

        return <i>{t("Media messages are not supported")}</i>;
    };

    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === "ArrowUp" || e.key === "ArrowDown") {
            const newFocusValue = message.index + (e.key === "ArrowUp" ? -1 : 1);
            updateFocus(newFocusValue);
        }
    };

    const handleMouseDown = () => {
        setIsMouseDown(true);
    };

    const handleMouseUp = () => {
        setIsMouseDown(false);
    };

    const handleFocus = () => {
        // Ignore focus from clicks
        if (!isMouseDown) {
            // Necessary since screen readers can set the focus to any focusable element
            updateFocus(message.index);
        }
    };

    const author = users?.find((u) => u.identity === message.author)?.friendlyName ?? message.author;
    if (attributes?.selectableClientResponse) return null;

    return (
        <Box
            {...outerContainerStyles}
            tabIndex={focusable ? 0 : -1}
            onFocus={handleFocus}
            onKeyDown={handleKeyDown}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            ref={messageRef}
            data-message-bubble
            data-testid="message-bubble"
        >
            <Box {...bubbleAndAvatarContainerStyles}>
                <Box {...getInnerContainerStyles(belongsToCurrentUser)}>
                    <Flex hAlignContent="between" width="100%" vAlignContent="center" marginBottom="space30">
                        {author === BOT_NAME ? (
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center"
                                }}
                            >
                                <div
                                    style={{
                                        backgroundColor: "#111827",
                                        padding: "4px",
                                        marginBottom: "0",
                                        lineHeight: "0",
                                        borderRadius: "3px",
                                        marginRight: "8px"
                                    }}
                                >
                                    <Icon icon="bot" size="sizeIcon10" color="colorTextWeakest" />
                                </div>
                                <Text
                                    {...authorStyles}
                                    as="p"
                                    aria-hidden
                                    style={{ textOverflow: "ellipsis" }}
                                    title={author}
                                >
                                    {author}
                                </Text>
                            </div>
                        ) : (
                            <Text
                                {...authorStyles}
                                as="p"
                                aria-hidden
                                style={{ textOverflow: "ellipsis" }}
                                title={t("You")}
                            >
                                {author === "Client" ? t("You") : author ?? t("Agent")}
                            </Text>
                        )}

                        <ScreenReaderOnly as="p">
                            {belongsToCurrentUser ? t("You sent at") : t("They sent at", { author })}
                        </ScreenReaderOnly>
                        <Text {...timeStampStyles} as="p">
                            {t("timeOnly", { val: message?.dateCreated, timeStyle: "short" })}
                        </Text>
                    </Flex>
                    <Text as="p" {...bodyStyles}>
                        {message.body ? parseMessageBody(message.body, belongsToCurrentUser) : null}
                    </Text>
                    {message.type === "media" ? renderMedia() : null}
                </Box>
            </Box>
            {read && (
                <Flex hAlignContent="right" vAlignContent="center" marginTop="space20">
                    <Text as="p" {...readStatusStyles}>
                        {t("Read")}
                    </Text>
                    <SuccessIcon decorative={true} size="sizeIcon10" color="colorTextWeak" />
                </Flex>
            )}
            {attributes.options && <MessageBubbleSelectable message={message} />}
        </Box>
    );
};
