import { Box } from "@twilio-paste/core/box";
import { Text } from "@twilio-paste/core/text";
import { Button } from "@twilio-paste/core/button";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { sessionDataHandler } from "../sessionDataHandler";
import {
    changeEngagementPhase,
    updateConversationRated,
    updatePreEngagementData,
    updateUnauthorizedFormVisibility
} from "../store/actions/genericActions";
import { EngagementPhase } from "../store/definitions";
import { containerStyles, textStyles, titleStyles } from "./styles/ConversationEnded.styles";

export const ConversationEnded = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation("translation", { keyPrefix: "ConversationEnded" });

    const handleStartNewChat = () => {
        sessionDataHandler.clear();
        dispatch(updatePreEngagementData({ email: "", name: "", query: "" }));
        dispatch(updateUnauthorizedFormVisibility({ unauthorizedFormSubmitted: false }));
        dispatch(updateConversationRated({ conversationRated: false }));
        dispatch(changeEngagementPhase({ phase: EngagementPhase.InitialPhase }));
    };

    return (
        <Box {...containerStyles}>
            <Text element="TITLE_TEXT" as="h3" {...titleStyles}>
                {t("Thanks for chatting with us.")}
            </Text>
            <Text as="p" {...textStyles}>
                {t("If you have any more questions, feel free to reach out again.")}
            </Text>
            <Button
                variant="primary"
                element="MANAGE_BUTTONS_PRIMARY"
                data-test="start-new-chat-button"
                fullWidth
                onClick={handleStartNewChat}
            >
                {t("Start new chat-button")}
            </Button>
        </Box>
    );
};
