import { useDispatch, useSelector } from "react-redux";
import { FunctionComponent, useEffect } from "react";

import { RootContainer } from "./RootContainer";
import { EngagementPhase } from "../store/definitions";
import { sessionDataHandler } from "../sessionDataHandler";
import { initSession } from "../store/actions/initActions";
import { addNotification, changeEngagementPhase } from "../store/actions/genericActions";
import { notifications } from "../notifications";
import { getTheme } from "../selectors";
import { WriskCustomisationProvider } from "./WriskCustomisationProvider";

export const WebchatWidget: FunctionComponent = () => {
    const theme = useSelector(getTheme);
    const dispatch = useDispatch();

    useEffect(() => {
        const data = sessionDataHandler.tryResumeExistingSession();
        const logger = window.Twilio.getLogger("WebChatWidget");
        if (data) {
            try {
                logger.info("Initializing session.");
                dispatch(
                    initSession({
                        token: data.token,
                        conversationSid: data.conversationSid,
                        currentPhase: EngagementPhase.MessagingCanvas
                    })
                );
            } catch (e) {
                logger.error("Something wrong with the store token or conversation sis. Changing engagement phase.");
                dispatch(addNotification(notifications.failedToInitSessionNotification((e as Error).message)));
            }
        } else {
            logger.info("Initializing session. 2");
            dispatch(changeEngagementPhase({ phase: EngagementPhase.InitialPhase }));
        }
    }, [dispatch]);

    return (
        <WriskCustomisationProvider theme={theme?.overrides} elements={theme?.elements}>
            <RootContainer />
        </WriskCustomisationProvider>
    );
};
