import merge from "lodash.merge";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { Logger, LogLevelDesc } from "loglevel";

import { store } from "./store/store";
import { WebchatWidget } from "./components/WebchatWidget";
import { sessionDataHandler } from "./sessionDataHandler";
import { initConfig, loadUserConfig } from "./store/actions/initActions";
import { changeExpandedStatus, changeEntryPointHiding } from "./store/actions/genericActions";
import { ConfigState, UserState } from "./store/definitions";
import { initLogger, getLogger } from "./logger";
import { DEFAULT_BRAND_NAME, USER_DATA, LOCALSTORAGE_SESSION_ITEM_ID } from "./constants";
import { createTheme, createElements, createIcons } from "./branding";
import i18nInit from "./i18n";

const createDefaultConfig = (brandName: string): ConfigState => {
    return {
        serverUrl: process.env.REACT_APP_SERVER_URL,
        theme: {
            overrides: createTheme(brandName),
            elements: createElements(brandName),
            icons: createIcons(brandName)
        },
        fileAttachment: {
            enabled: true,
            maxFileSize: 16777216, // 16 MB
            acceptedExtensions: [
                /* cSpell:disable */
                "jpg",
                "jpeg",
                "jpe",
                "jif",
                "jfif",
                "jfi",
                "gif",
                "jp2",
                "j2k",
                "jpf",
                "jpx",
                "jpm",
                "mj2",
                "png",
                "mp4",
                "pdf",
                "txt",
                "docx",
                "doc",
                "docm",
                "dot",
                "dotx",
                "dotm",
                "rtf",
                "odt",
                "tif",
                "tiff",
                "webp",
                "heif",
                "heic" /* cSpell:enable */
            ]
        }
    };
};

const initWebchat = async (originalConfig: ConfigState) => {
    await i18nInit(originalConfig);
    const brandConfig = createDefaultConfig(originalConfig.brandName ?? DEFAULT_BRAND_NAME);

    const mergedConfig = merge({}, brandConfig, originalConfig);

    sessionDataHandler.setEndpoint(mergedConfig.serverUrl);
    store.dispatch(initConfig(mergedConfig));

    const rootElement = document.getElementById("twilio-webchat-widget-root");
    const logger = window.Twilio.getLogger("initWebChat");

    logger.info("Now rendering the webchat");

    render(
        <Provider store={store}>
            <WebchatWidget />
        </Provider>,
        rootElement
    );

    if (window.Cypress) {
        window.store = store;
    }
};

const expandWebChat = () => {
    store.dispatch(changeExpandedStatus({ expanded: true }));
};

const temporarilyHideEntryPoint = (hide: boolean) => {
    store.dispatch(changeEntryPointHiding(hide));
};

const updateChatUser = (config: UserState) => {
    localStorage.setItem(USER_DATA, JSON.stringify(config));
    store.dispatch(loadUserConfig(config));
};

const removeChatUser = () => {
    localStorage.removeItem(USER_DATA);
    localStorage.removeItem(LOCALSTORAGE_SESSION_ITEM_ID);
};

declare global {
    // noinspection JSUnusedGlobalSymbols
    interface Window {
        Twilio: {
            initWebchat: (config: ConfigState) => void;
            initLogger: (level?: LogLevelDesc) => void;
            getLogger: (className: string) => Logger;
            expandWebChat: () => void;
            updateChatUser: (config: UserState) => void;
            removeChatUser: () => void;
            temporarilyHideEntryPoint: (hide: boolean) => void;
        };
        Cypress: Cypress.Cypress;
        store: typeof store;
    }
}

// Expose `initWebchat` function to window object
Object.assign(window, {
    Twilio: {
        initWebchat,
        initLogger,
        getLogger,
        expandWebChat,
        updateChatUser,
        removeChatUser,
        temporarilyHideEntryPoint
    }
});
