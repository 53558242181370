import { ReactElement } from "react";
import { Anchor } from "@twilio-paste/core/anchor";

const protocol = "((?:http|https|rtsp):\\/\\/)";
const startOfMatchedUrl = "(?:\\b|^)";
const endOfMatchedUrl = "(?:\\b|$)";

const phoneRegex = /\b\d{4} \d{3} \d{4}\b/g;
const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}\b/g;

// only captured group is the protocol, e.g. https://
const urlRegexString = `${startOfMatchedUrl}${protocol}\\S+${endOfMatchedUrl}`;
const urlRegex = new RegExp(urlRegexString, "gi");

const getAnchor = (href: string, index: number | string, belongToCurrentUser: boolean, text: string) => {
    return (
        <Anchor href={href} key={index} variant={belongToCurrentUser ? "inverse" : "default"}>
            {text}
        </Anchor>
    );
};

export const parseMessageBody = (body: string, belongToCurrentUser: boolean) => {
    const renderedResult: Array<ReactElement | string> = [];
    const lineBreakRegexp = new RegExp(/[\n\r]/);
    const lines = body.split(lineBreakRegexp);
    lines.forEach((line) => {
        let index = 0;
        let regexResult;

        while ((regexResult = phoneRegex.exec(line))) {
            renderedResult.push(line.substring(index, regexResult.index));
            const [tel, telProtocol] = regexResult;
            const telHref = telProtocol ? tel : `tel:${tel}`;
            renderedResult.push(getAnchor(telHref, index, belongToCurrentUser, tel));
            index = regexResult.index + regexResult[0].length;
        }

        while ((regexResult = urlRegex.exec(line))) {
            // add all regular body text that comes before the url
            renderedResult.push(line.substring(index, regexResult.index));
            const [url, urlProtocol] = regexResult;
            const urlHref = urlProtocol ? url : `http://${url}`;
            renderedResult.push(getAnchor(urlHref, `u-${index}`, belongToCurrentUser, url));
            index = regexResult.index + regexResult[0].length;
        }

        while ((regexResult = emailRegex.exec(line))) {
            renderedResult.push(line.substring(index, regexResult.index));
            const [email, emailProtocol] = regexResult;
            const emailHref = emailProtocol ? email : `mailto:${email}`;
            renderedResult.push(getAnchor(emailHref, index, belongToCurrentUser, email));
            index = regexResult.index + regexResult[0].length;
        }

        // add all regular body text that comes after the url
        if (index < line.length) {
            renderedResult.push(line.substring(index, line.length));
        }

        renderedResult.push("\n");
    });

    // remove the newline added after the last parsed line
    renderedResult.pop();

    return renderedResult;
};
