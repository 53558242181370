import { useEffect } from "react";
import { Box } from "@twilio-paste/core/box";
import { useDispatch, useSelector } from "react-redux";

import { changeExpandedStatus } from "../store/actions/genericActions";
import { getContainerStyles } from "./styles/EntryPoint.styles";
import { Icon } from "./Icon";
import { isExpanded } from "../selectors";

export const EntryPoint = () => {
    const dispatch = useDispatch();
    const expanded = useSelector(isExpanded);

    useEffect(() => {
        const handleResize = () => {
            const isMobileView = window.innerWidth <= 640;

            if (isMobileView && expanded) {
                document.body.style.overflow = "hidden";
            } else {
                document.body.style.overflow = "auto";
            }
        };

        window.addEventListener("resize", handleResize);
        handleResize();

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [expanded]);

    const onClickHandler = () => {
        dispatch(changeExpandedStatus({ expanded: !expanded }));
    };

    return (
        <Box as="button" data-test="entry-point-button" onClick={onClickHandler} {...getContainerStyles(expanded)}>
            {expanded ? <Icon icon="chevron" size="sizeIcon40" /> : <Icon icon="chat" size="sizeIcon40" />}
        </Box>
    );
};
