import { AppState, PreEngagementData } from "./store/definitions";
import { DEFAULT_BRAND_NAME } from "./constants";

export const getTheme = (state: AppState) => state.config.theme;
export const getIcons = (state: AppState) => state.config.theme?.icons;

export const getFileAttachments = (state: AppState) => ({
    attachedFiles: state.chat.attachedFiles ?? [],
    fileAttachmentConfig: state.config.fileAttachment
});

export const isExpanded = (state: AppState) => state.session.expanded;

export const getFileAttachment = (state: AppState) => state.config.fileAttachment;

export const getBrandName = (state: AppState) => state.config.brandName ?? DEFAULT_BRAND_NAME;

export const getFirstName = (state: AppState) => state.user.firstName ?? "";

export const getMessageBubbleData = (state: AppState) => ({
    conversationsClient: state.chat.conversationsClient,
    participants: state.chat.participants,
    users: state.chat.users,
    fileAttachmentConfig: state.config.fileAttachment,
    unauthorizedFormSubmitted: state.session.unauthorizedFormSubmitted
});

export const getMessageInputData = (state: AppState) => ({
    conversation: state.chat.conversation,
    attachedFiles: state.chat.attachedFiles ?? [],
    fileAttachmentConfig: state.config.fileAttachment,
    displayInput: state.session.showMessageInput
});

export const getConversation = (state: AppState) => ({
    conversation: state.chat.conversation
});

export const getConversationState = (state: AppState) => ({
    conversationState: state.chat.conversationState,
    conversationRated: state.session.conversationRated
});

export const getMessageList = (state: AppState) => ({
    messages: state.chat.messages,
    participants: state.chat.participants,
    users: state.chat.users,
    conversation: state.chat.conversation,
    conversationsClient: state.chat.conversationsClient,
    isBotTyping: state.session.isBotTyping
});

export const getNotifications = (store: AppState) => store.notifications;

export const getPreEngagement = (state: AppState): Partial<PreEngagementData> => state.session.preEngagementData ?? {};
