/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { addNotification, changeEngagementPhase, updateMessageInputVisibility } from "../store/actions/genericActions";
import { EngagementPhase } from "../store/definitions";
import { USER_DATA, LOCALSTORAGE_SESSION_ITEM_ID } from "../constants";
import { sessionDataHandler } from "../sessionDataHandler";
import { initSession } from "../store/actions/initActions";
import { notifications } from "../notifications";
import { getBrandName } from "../selectors";

export const InitialPhase = () => {
    const brandName: string = useSelector(getBrandName);
    const sessionValid: boolean = JSON.parse(localStorage.getItem(LOCALSTORAGE_SESSION_ITEM_ID) ?? "false");
    const userDataJson = localStorage.getItem(USER_DATA);
    const dispatch = useDispatch();
    const { t } = useTranslation("translation", { keyPrefix: "InitialPhase" });

    const initializeChat = async () => {
        try {
            dispatch(changeEngagementPhase({ phase: EngagementPhase.Loading }));
            dispatch(updateMessageInputVisibility({ showMessageInput: false }));
            const userData = userDataJson !== null ? JSON.parse(userDataJson) : null;

            const friendlyName =
                userData?.firstName || userData?.lastName
                    ? `${userData?.firstName} ${userData?.lastName}`
                    : t("Client");
            const data = await sessionDataHandler.fetchAndStoreNewSession({
                formData: {
                    friendlyName,
                    brandName,
                    location: window.location?.href ?? window.location,
                    email: userData?.emailAddress || null,
                    query: null
                }
            });
            dispatch(
                initSession({
                    token: data.token,
                    conversationSid: data.conversationSid,
                    currentPhase: EngagementPhase.MessagingCanvas
                })
            );
        } catch (err) {
            dispatch(addNotification(notifications.failedToInitSessionNotification((err as Error).message)));
            dispatch(changeEngagementPhase({ phase: EngagementPhase.InitialPhase }));
        }
    };

    useEffect(() => {
        if (!sessionValid) {
            initializeChat();
        } else {
            dispatch(changeEngagementPhase({ phase: EngagementPhase.MessagingCanvas }));
        }
    }, []);

    return null;
};
