import getFetchWithRetry from "fetch-retry";

import { Token } from "./definitions";
import { generateSecurityHeaders } from "./utils/generateSecurityHeaders";
import { LOCALSTORAGE_SESSION_ITEM_ID } from "./constants";

const fetchRetry = getFetchWithRetry(fetch);

let _endpoint = process.env.REACT_APP_SERVER_URL ?? "";

type SessionDataStorage = Token & {
    loginTimestamp: number | null;
};

async function contactBackend<T>(endpointRoute: string, body: Record<string, unknown> = {}): Promise<T> {
    const securityHeaders = await generateSecurityHeaders();
    const logger = window.Twilio.getLogger("SessionDataHandler");
    const response = await fetchRetry(_endpoint + endpointRoute, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            ...securityHeaders
        },
        body: JSON.stringify(body)
    });
    if (!response.ok) {
        logger.error("Request to backend failed: ", endpointRoute);
        throw new Error(`Request to backend failed: ${endpointRoute}`);
    }

    // Do not attempt to parse 204s no content
    return [200, 201, 202, 203].includes(response?.status) ? response.json() : null;
}

function storeSessionData(data: SessionDataStorage) {
    localStorage.setItem(LOCALSTORAGE_SESSION_ITEM_ID, JSON.stringify(data));
}

function getStoredSessionData() {
    const item = localStorage.getItem(LOCALSTORAGE_SESSION_ITEM_ID);
    const logger = window.Twilio.getLogger("SessionDataHandler");
    let storedData: Token;

    if (!item) return null;

    try {
        storedData = JSON.parse(item);
    } catch (e) {
        logger.error("Couldn't parse locally stored data");
        return null;
    }

    return storedData as SessionDataStorage;
}

export const sessionDataHandler = {
    setEndpoint(endpoint: string = "") {
        _endpoint = endpoint;
    },

    getEndpoint() {
        return _endpoint;
    },

    tryResumeExistingSession(): Token | null {
        const logger = window.Twilio.getLogger("SessionDataHandler");
        logger.info("trying to refresh existing session");
        const storedTokenData = getStoredSessionData();

        if (!storedTokenData) {
            logger.warn("no tokens stored, no session to refresh");
            this.clear();
            return null;
        }

        if (Date.now() >= new Date(storedTokenData.expiration).getTime()) {
            logger.warn("token expired, ignoring existing sessions");
            this.clear();
            return null;
        }

        logger.info("existing token still valid, using existing session data");

        storeSessionData({
            ...storedTokenData,
            loginTimestamp: storedTokenData.loginTimestamp ?? null
        });
        return storedTokenData;
    },

    async getUpdatedToken(): Promise<Token> {
        const logger = window.Twilio.getLogger("SessionDataHandler");
        logger.info("trying to get updated token from BE");
        const storedTokenData = getStoredSessionData();

        if (!storedTokenData) {
            logger.error("Can't update token: current token doesn't exist");
            throw Error("Can't update token: current token doesn't exist");
        }

        let newTokenData: Token;

        try {
            newTokenData = await contactBackend<Token>("/refreshToken", {
                token: storedTokenData.token
            });
        } catch (e) {
            logger.error(`Something went wrong when trying to get an updated token: ${e}`);
            throw Error(`Something went wrong when trying to get an updated token: ${e}`);
        }

        // Server won't return a conversation SID, so we merge the existing data with the latest one
        const updatedSessionData = {
            ...storedTokenData,
            ...newTokenData
        };

        storeSessionData(updatedSessionData);
        return updatedSessionData;
    },

    fetchAndStoreNewSession: async ({ formData }: { formData: Record<string, unknown> }) => {
        const logger = window.Twilio.getLogger("SessionDataHandler");
        logger.info("trying to create new session");
        const loginTimestamp = Date.now();

        let newTokenData;

        try {
            newTokenData = await contactBackend<Token>("/initWebchat", { formData });
        } catch (e) {
            // eslint-disable-next-line sonarjs/no-duplicate-string
            logger.error("No results from server");
            throw Error("No results from server");
        }

        logger.info("new session successfully created");
        storeSessionData({
            ...newTokenData,
            loginTimestamp
        });

        return newTokenData;
    },

    updateConversationAttributes: async (attributes: { [key: string]: any }) => {
        const logger = window.Twilio.getLogger("SessionDataHandler - - update conversation attributes");
        try {
            await contactBackend<Token>("/updateConversationAttributes", { conversationAttributes: attributes });
        } catch (e) {
            logger.error("No results from updateConversationAttributes");
            throw Error("No results from server - updateConversationAttributes");
        }
    },

    updateTaskConversationAttributes: async (conversationSid: string, attributes: { [key: string]: any }) => {
        const logger = window.Twilio.getLogger("SessionDataHandler - update task conversation attributes");
        try {
            await contactBackend<Token>("/rating/updateTaskConversationAttributes", {
                taskConversationAttributes: attributes,
                conversationSid
            });
        } catch (e) {
            logger.error("Bad request from serverless - updateTaskConversationAttributes", e);
            throw e;
        }
    },

    stopTrackingTask: async (conversationSid: string) => {
        const logger = window.Twilio.getLogger("SessionDataHandler - stop tracking tasks");
        try {
            await contactBackend<Token>("/tracking/stop-task", { conversationSid });
        } catch (e) {
            logger.error("No results from rating - stop tracking task");
            throw Error("No results from server - stop tracking task");
        }
    },

    clear: () => {
        localStorage.removeItem(LOCALSTORAGE_SESSION_ITEM_ID);
        localStorage.removeItem("unauthorizedFormSubmitted");
        localStorage.removeItem("conversationRated");
        localStorage.removeItem("disabledButtons");
        localStorage.removeItem("clickedButtons");
    }
};
