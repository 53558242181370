/* eslint-disable import/no-unused-modules */
import { BoxStyleProps } from "@twilio-paste/core/box";
import { TextStyleProps } from "@twilio-paste/core/text";

export const containerStyles: BoxStyleProps = {
    padding: "space60",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: "colorBackgroundBody",
    textAlign: "center",
    boxShadow: "shadowBorderPrimaryStrong"
};

export const titleStyles: TextStyleProps = {
    fontSize: "fontSize50",
    fontWeight: "fontWeightExtrabold",
    fontFamily: "fontFamilyDisplay"
};

export const textStyles: TextStyleProps = {
    marginBottom: "space50",
    marginTop: "space50"
};

export const loadingTextStyles: TextStyleProps = {
    fontSize: ["fontSize60"],
    margin: "space50",
    textAlign: "center"
};

export const mainCCClosedText: TextStyleProps = {
    fontSize: ["fontSize60"],
    fontWeight: "fontWeightExtrabold",
    lineHeight: "lineHeight40"
};
