import { ValueOf } from "@twilio-paste/core/types";

import { Notification } from "./store/definitions";
import { roundFileSizeInMB } from "./utils/roundFileSizeInMB";

export declare const AlertVariants: {
    readonly ERROR: "error";
    readonly NEUTRAL: "neutral";
    readonly WARNING: "warning";
};

export type AlertVariants = ValueOf<typeof AlertVariants>;

const shortenFileName = (string: string, maxChar = 60) => {
    const [, filename, fileExtension] = string.match(/^(.+)(\.[\S]*)$/) ?? [];

    const shortenedFilename = filename.substring(0, maxChar);
    return `${shortenedFilename}${shortenedFilename !== filename ? "[...]" : ""}${fileExtension ?? ""}`;
};

const fileAttachmentAlreadyAttachedNotification = ({ fileName }: { fileName: string }): Notification => ({
    id: `FileAttachmentAlreadyAttachedNotification_${Math.random()}`,
    dismissible: true,
    messageKey: "{{fileName}} is already attached.",
    messageOptions: {
        fileName: shortenFileName(fileName)
    },
    type: "error",
    timeout: 10000
});

const fileAttachmentInvalidSizeNotification = ({
    fileName,
    maxFileSize
}: {
    fileName: string;
    maxFileSize: number;
}): Notification => ({
    id: `FileAttachmentInvalidSizeNotification_${Math.random()}`,
    dismissible: true,
    messageKey: "{{fileName}} can’t be attached because the file is too large. Maximum file size is {{maxFileSize}}MB",
    messageOptions: {
        maxFileSize: roundFileSizeInMB(maxFileSize),
        fileName: shortenFileName(fileName)
    },
    type: "error",
    timeout: 10000
});

const fileAttachmentInvalidTypeNotification = ({ fileName }: { fileName: string }): Notification => ({
    id: `FileAttachmentInvalidTypeNotification_${Math.random()}`,
    dismissible: true,
    messageKey: "{{fileName}} can’t be attached because that file type isn’t supported. Please try a different file.",
    messageOptions: {
        fileName: shortenFileName(fileName)
    },
    type: "error",
    timeout: 10000
});

const fileDownloadInvalidSizeNotification = ({
    fileName,
    maxFileSize
}: {
    fileName: string;
    maxFileSize: number;
}): Notification => ({
    id: `FileDownloadInvalidSizeNotification_${Math.random()}`,
    dismissible: true,
    messageKey:
        "{{fileName}} can’t be downloaded because the file is too large. Maximum file size is {{maxFileSize}}MB",
    messageOptions: {
        fileName: shortenFileName(fileName),
        maxFileSize: roundFileSizeInMB(maxFileSize)
    },
    type: "error",
    timeout: 10000
});

const fileDownloadInvalidTypeNotification = ({ fileName }: { fileName: string }): Notification => ({
    id: `FileDownloadInvalidTypeNotification_${Math.random()}`,
    dismissible: true,
    messageKey: "{{fileName}} can’t be downloaded because the file type isn’t supported.",
    messageOptions: {
        fileName: shortenFileName(fileName)
    },
    type: "error",
    timeout: 10000
});

const noConnectionNotification = (): Notification => ({
    id: "NoConnectionNotification",
    dismissible: true,
    messageKey: "Connection lost. Attempting to reconnect.",
    type: "warning"
});

const failedToInitSessionNotification = (error: string): Notification => ({
    id: `FailedToInitSessionNotification`,
    dismissible: true,
    messageKey: "Something went wrong. {{error}}. Please try again later.",
    messageOptions: { error },
    type: "error"
});

const failedToSendMessageNotification = (error: string): Notification => ({
    id: `FailedToSendMessageNotification`,
    dismissible: true,
    messageKey: "Got an error while send message. {{error}}. Please try again later.",
    messageOptions: { error },
    type: "error"
});

export const notifications = {
    fileAttachmentAlreadyAttachedNotification,
    fileAttachmentInvalidSizeNotification,
    fileAttachmentInvalidTypeNotification,
    fileDownloadInvalidSizeNotification,
    fileDownloadInvalidTypeNotification,
    noConnectionNotification,
    failedToInitSessionNotification,
    failedToSendMessageNotification
};
