import { Box } from "@twilio-paste/core/box";
import { useSelector } from "react-redux";

import { MessagingCanvasPhase } from "./MessagingCanvasPhase";
import { AppState, EngagementPhase } from "../store/definitions";
import { LoadingPhase } from "./LoadingPhase";
import { EntryPoint } from "./EntryPoint";
import { InitialStaticPage } from "./InitialStaticPage";
import { InitialPhase } from "./InitialPhase";
import { OutsideWorkingHoursPage } from "./OutsideWorkingHoursPage";
import { UnauthorizedEngagementFormPhase } from "./UnauthorizedEngagementFormPhase";
import { RatingPage } from "./RatingPage";
import { innerContainerStyles, getOuterContainerStyles } from "./styles/RootContainer.styles";

const getPhaseComponent = (phase: EngagementPhase) => {
    switch (phase) {
        case EngagementPhase.Loading:
            return <LoadingPhase />;
        case EngagementPhase.MessagingCanvas:
            return <MessagingCanvasPhase />;
        case EngagementPhase.InitialPhase:
            return <InitialPhase />;
        case EngagementPhase.InitialStaticPage:
            return <InitialStaticPage />;
        case EngagementPhase.RatingPhase:
            return <RatingPage />;
        case EngagementPhase.UnauthorizedFormPhase:
            return <UnauthorizedEngagementFormPhase />;
        case EngagementPhase.CCClosedPage:
            return <OutsideWorkingHoursPage />;
        default:
            return <InitialPhase />;
    }
};

export function RootContainer() {
    const { currentPhase, expanded, temporarilyHideEntryPoint } = useSelector(({ session }: AppState) => ({
        currentPhase: session.currentPhase,
        expanded: session.expanded,
        temporarilyHideEntryPoint: session.temporarilyHideEntryPoint
    }));

    return (
        <Box {...getOuterContainerStyles(expanded)}>
            {expanded && (
                <Box data-test="root-container" {...innerContainerStyles}>
                    {getPhaseComponent(currentPhase)}
                </Box>
            )}
            {(expanded || !temporarilyHideEntryPoint) && <EntryPoint />}
        </Box>
    );
}
