import { Input } from "@twilio-paste/core/input";
import { Label } from "@twilio-paste/core/label";
import { Box } from "@twilio-paste/core/box";
import { TextArea } from "@twilio-paste/core/textarea";
import { FormEvent } from "react";
import { Button } from "@twilio-paste/core/button";
import { useDispatch, useSelector } from "react-redux";
import { Text } from "@twilio-paste/core/text";
import { useTranslation } from "react-i18next";

import {
    addNotification,
    changeEngagementPhase,
    updatePreEngagementData,
    updateUnauthorizedFormVisibility
} from "../store/actions/genericActions";
import { ConversationAttributes, EngagementPhase } from "../store/definitions";
import { Header } from "./Header";
import { notifications } from "../notifications";
import { NotificationBar } from "./NotificationBar";
import { introStyles, fieldStyles, formStyles } from "./styles/PreEngagementFormPhase.styles";
import { sessionDataHandler } from "../sessionDataHandler";
import { getConversation, getPreEngagement } from "../selectors";

export const UnauthorizedEngagementFormPhase = () => {
    const logger = window.Twilio.getLogger("UnauthorizedEngagementForm");
    const { name, email, query } = useSelector(getPreEngagement);
    const { conversation } = useSelector(getConversation);
    const dispatch = useDispatch();
    const { t } = useTranslation("translation", { keyPrefix: "UnauthorizedEngagementFormPhase" });

    const send = async () => {
        if (!conversation) {
            logger.error("Failed sending message: no conversation found");
            return;
        }
        let preparedMessage = conversation.prepareMessage();
        preparedMessage = preparedMessage.setBody(`I'm ${name}, my email is ${email}.\n\n${query}`);
        await preparedMessage.build().send();
        await sessionDataHandler.updateConversationAttributes({
            conversationSid: conversation.sid,
            clientFirstName: name,
            clientEmail: email,
            clientQuery: query
        } as ConversationAttributes);
        dispatch(changeEngagementPhase({ phase: EngagementPhase.MessagingCanvas }));
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        try {
            await send();
            localStorage.setItem("unauthorizedFormSubmitted", "true");
            dispatch(updateUnauthorizedFormVisibility({ unauthorizedFormSubmitted: true }));
        } catch (err) {
            dispatch(addNotification(notifications.failedToSendMessageNotification((err as Error).message)));
            dispatch(changeEngagementPhase({ phase: EngagementPhase.InitialStaticPage }));
        }
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
        }
    };

    const handleStartNewChat = () => {
        sessionDataHandler.clear();
        dispatch(updatePreEngagementData({ email: "", name: "", query: "" }));
        dispatch(updateUnauthorizedFormVisibility({ unauthorizedFormSubmitted: false }));
        dispatch(changeEngagementPhase({ phase: EngagementPhase.InitialPhase }));
    };

    return (
        <>
            <Header />
            <NotificationBar />
            <Box as="form" data-test="unauthorized-engagement-chat-form" onSubmit={handleSubmit} {...formStyles}>
                <Text {...introStyles} as="p" marginTop="space40">
                    {t("Please provide your contact information")}
                </Text>
                <Box {...fieldStyles}>
                    <Label htmlFor="name">{t("Name-label")}</Label>
                    <Input
                        type="text"
                        placeholder={t("Your name-placeholder")}
                        name="name"
                        element="MESSAGE_INPUT_FORM"
                        data-test="unauthorized-engagement-chat-form-name-input"
                        value={name}
                        onChange={(e) => dispatch(updatePreEngagementData({ name: e.target.value }))}
                        pattern="[A-Za-z0-9' ]{1,}"
                        required
                    />
                </Box>
                <Box {...fieldStyles}>
                    <Label htmlFor="email">{t("Email address-label")}</Label>
                    <Input
                        type="email"
                        placeholder={t("Your email-placeholder")}
                        name="email"
                        element="MESSAGE_INPUT_FORM"
                        data-test="unauthorized-engagement-chat-form-email-input"
                        value={email}
                        onChange={(e) => dispatch(updatePreEngagementData({ email: e.target.value }))}
                        required
                    />
                </Box>

                <Box {...fieldStyles}>
                    <Label htmlFor="query">{t("How can we help you?-label")}</Label>
                    <TextArea
                        placeholder={t("Please enter your question-placeholder")}
                        name="query"
                        data-test="unauthorized-engagement-chat-form-query-textarea"
                        element="MESSAGE_INPUT_FORM"
                        value={query}
                        onChange={(e) => dispatch(updatePreEngagementData({ query: e.target.value }))}
                        onKeyDown={handleKeyPress}
                        required
                    />
                </Box>

                <Box {...fieldStyles}>
                    <Button
                        variant="primary"
                        element="MANAGE_BUTTONS_PRIMARY"
                        type="submit"
                        data-test="unauthorized-engagement-start-chat-button"
                        fullWidth
                    >
                        {t("Connect to agent-button")}
                    </Button>
                    <p />
                    <Button
                        variant="secondary"
                        element="MANAGE_BUTTONS_SECONDARY"
                        type="button"
                        fullWidth
                        data-test="unauthorized-engagement-close-session-button"
                        onClick={handleStartNewChat}
                    >
                        {t("Back to bot-button")}
                    </Button>
                </Box>
            </Box>
        </>
    );
};
